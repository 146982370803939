.swiper-slide img.object-none {
  object-fit: none;
  width: auto;
  height: 100%;
}



.disabledFullscreen {
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  video::-webkit-media-controls-play-button {
  }
  video::-webkit-media-controls-play-button {
  }
  video::-webkit-media-controls-timeline {
  }
  video::-webkit-media-controls-current-time-display {
  }
  video::-webkit-media-controls-time-remaining-display {
  }
  video::-webkit-media-controls-time-remaining-display {
  }
  video::-webkit-media-controls-mute-button {
  }
  video::-webkit-media-controls-toggle-closed-captions-button {
  }
  video::-webkit-media-controls-volume-slider {
  }
}
